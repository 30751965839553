import firebase from "firebase/app";
import "firebase/firestore";

var firebaseConfig = {
    apiKey: "AIzaSyBA85olf0HCbHxbmpJgoYWntfgeJrfLtlA",
    authDomain: "rmd-wine.firebaseapp.com",
    projectId: "rmd-wine",
    storageBucket: "rmd-wine.appspot.com",
    messagingSenderId: "866101568645",
    appId: "1:866101568645:web:826193604c0c295304f5ec",
    measurementId: "G-M2XCTE98R1"
  };

  const firebaseApp = firebase.initializeApp(firebaseConfig);
  const timestamp = firebase.firestore.FieldValue.serverTimestamp;

  export {timestamp};
  export default firebaseApp.firestore();