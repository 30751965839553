import Vue from "vue";
import VueRouter from "vue-router";
import firebase from "firebase/app";
import "firebase/auth";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import Admin from "../views/Admin.vue";
import CreateWine from "../views/CreateWine.vue";
import EditWine from "../views/EditWine.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: 'Home',
    },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      title: 'Login',
    },
  },
  {
    path: "/admin",
    name: "Admin",
    component: Admin,
    meta: {
      requiresAuth: true,
      title: 'Admin',
    },
  },
  {
    path: "/createwine",
    name: "Create Wine",
    component: CreateWine,
    meta: {
      requiresAuth: true,
      title: 'Create Wine',
    },
  },
  {
    path: "/editwine",
    name: "Edit Wine",
    component: EditWine,
    meta: {
      requiresAuth: true,
      title: 'Edit Wine',
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title} | RMD.WINE`
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)

  if (requiresAuth && !firebase.auth().currentUser) {
    next('/');
  } else {
    next();
  }
  next();
})

export default router;
