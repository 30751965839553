<template>
    <div class="create-post">
        <div class="header">
            <button @click="$router.push('Admin')">Admin Panel</button>
        </div>
        <form @submit.prevent>
            <h2>Enter a Wine</h2>
            <Loading v-show="loading" />
            <div class="inputs">
                <div class="section">
                    <div class="section-heading">Details</div>
                    <hr class="divider">
                    <div class="question">
                        <div class="label">Vintage</div>
                        <div class="input">
                            <input v-model.trim="wine.vintage" type="number" placeholder="1998">
                        </div>
                    </div>
                    <div class="question">
                        <div class="label">Brand</div>
                        <div class="input">
                            <input v-model.trim="wine.brand" placeholder="Wölffer Estate">
                        </div>
                    </div>
                    <div class="question">
                        <div class="label">Name of Wine</div>
                        <div class="input">
                            <input v-model.trim="wine.name" placeholder="Cabernet Franc">
                        </div>
                    </div>
                </div>

                <div class="section">
                    <div class="section-heading">Appearance</div>
                    <hr class="divider">
                    <div class="question">
                        <div class="label">Intensity</div>
                        <select v-model.trim="wine.appearance_intensity" class="select-input">
                            <option value="pale">pale</option>
                            <option value="medium">medium</option>
                            <option value="deep">deep</option>
                        </select>
                    </div>
                    <div class="question">
                        <div class="label">Colour</div>
                        <div class="input">
                            <textarea v-model.trim="wine.appearance_colour" placeholder="comma, seperated, list"></textarea>
                        </div>
                    </div>
                </div>

                <div class="section">
                    <div class="section-heading">Nose</div>
                    <hr class="divider">
                    <div class="question">
                        <div class="label">Intensity</div>
                        <select v-model.trim="wine.nose_intensity" class="select-input">
                            <option value="light">light</option>
                            <option value="medium -">medium -</option>
                            <option value="medium">medium</option>
                            <option value="medium +">medium +</option>
                            <option value="pronounced">pronounced</option>
                        </select>
                    </div>
                    <div class="question">
                        <div class="label">Aroma Characteristics</div>
                        <div class="input">
                            <textarea v-model.trim="wine.nose_aroma_characteristics" placeholder="comma, seperated, list"></textarea>
                        </div>
                    </div>
                </div>

                <div class="section">
                    <div class="section-heading">Palate</div>
                    <hr class="divider">
                    <div class="question">
                        <div class="label">Sweetness</div>
                        <select v-model.trim="wine.palate_sweetness" class="select-input">
                            <option value="dry">dry</option>
                            <option value="off-dry">off-dry</option>
                            <option value="medium-dry">medium-dry</option>
                            <option value="medium-sweet">medium-sweet</option>
                            <option value="sweet">sweet</option>
                        </select>
                    </div>
                    <div class="question">
                        <div class="label">Acidity</div>
                        <select v-model.trim="wine.palate_acidity" class="select-input">
                            <option value="low">low</option>
                            <option value="medium -">medium -</option>
                            <option value="medium">medium</option>
                            <option value="medium +">medium +</option>
                            <option value="high">high</option>
                        </select>
                    </div>
                    <div class="question">
                        <div class="label">Tannin</div>
                        <div class="double-answer">
                            <select v-model.trim="wine.palate_tannin_level" class="select-input">
                                <option value="low">low</option>
                                <option value="medium -">medium -</option>
                                <option value="medium">medium</option>
                                <option value="medium +">medium +</option>
                                <option value="high">high</option>
                            </select>
                            <div class="input">
                                <textarea v-model.trim="wine.palate_tannin_nature" placeholder="comma, seperated, list"></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="question">
                        <div class="label">Alcohol</div>
                        <select v-model.trim="wine.palate_alcohol" class="select-input">
                            <option value="low">low</option>
                            <option value="medium">medium</option>
                            <option value="high">high</option>
                        </select>
                    </div>
                    <div class="question">
                        <div class="label">Body</div>
                        <select v-model.trim="wine.palate_body" class="select-input">
                            <option value="light">light</option>
                            <option value="medium -">medium -</option>
                            <option value="medium">medium</option>
                            <option value="medium +">medium +</option>
                            <option value="full">full</option>
                        </select>
                    </div>
                    <div class="question">
                        <div class="label">Flavour Intensity</div>
                        <select v-model.trim="wine.palate_flavour_intensity" class="select-input">
                            <option value="low">low</option>
                            <option value="medium -">medium -</option>
                            <option value="medium">medium</option>
                            <option value="medium +">medium +</option>
                            <option value="pronounced">pronounced</option>
                        </select>
                    </div>
                    <div class="question">
                        <div class="label">Flavour Characteristics</div>
                        <div class="input">
                            <textarea v-model.trim="wine.palate_flavour_characteristics" placeholder="comma, seperated, list"></textarea>
                        </div>
                    </div>
                    <div class="question">
                        <div class="label">Other Observations</div>
                        <div class="input">
                            <textarea v-model.trim="wine.palate_other_observations" placeholder="comma, seperated, list"></textarea>
                        </div>
                    </div>
                    <div class="question">
                        <div class="label">Finish</div>
                        <select v-model.trim="wine.palate_finish" class="select-input">
                            <option value="short">short</option>
                            <option value="medium -">medium -</option>
                            <option value="medium">medium</option>
                            <option value="medium +">medium +</option>
                            <option value="long">long</option>
                        </select>
                    </div>
                </div>
                
                <div class="section">
                    <div class="section-heading">Conclusions</div>
                    <hr class="divider">
                    <div class="question">
                        <div class="label">Quality Assesment</div>
                        <div class="double-answer">
                            <select v-model.trim="wine.conclusions_quality_assesment" class="select-input">
                                <option value="poor">poor</option>
                                <option value="acceptable">acceptable</option>
                                <option value="good">good</option>
                                <option value="very good">very good</option>
                                <option value="outstanding">outstanding</option>
                            </select>
                            <div class="input">
                                <textarea v-model.trim="wine.conclusions_quality_assesment_notes" placeholder="Reasoning and explanation" rows="5"></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="question">
                        <div class="label">Bottle Ageing</div>
                        <div class="double-answer">
                            <select v-model.trim="wine.conclusions_bottle_ageing" class="select-input">
                                <option value="yes">yes</option>
                                <option value="no">no</option>
                            </select>
                            <div class="input">
                                <textarea v-model.trim="wine.conclusions_bottle_ageing_notes" placeholder="Reasoning and explanation" rows="5"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <button @click="updateWine" :disabled="isDisable">Update</button>
        </form>
    </div>
</template>

<script>
import firebase from "firebase/app";
import Loading from "../components/Loading";

export default {
    name: "EditWine",

    data() {
        return {
            loading: null,
            routeID: null,
            currentWine: null,
            wineRef: null,
            wine: {
                wineId: '',
                vintage: '',
                brand: '',
                name: '',
                appearance_intensity: '',
                appearance_colour: '',
                nose_intensity: '',
                nose_aroma_characteristics: '',
                palate_sweetness: '',
                palate_acidity: '',
                palate_tannin_level: '',
                palate_tannin_nature: '',
                palate_alcohol: '',
                palate_body: '',
                palate_flavour_intensity: '',
                palate_flavour_characteristics: '',
                palate_other_observations: '',
                palate_finish: '',
                conclusions_quality_assesment: '',
                conclusions_quality_assesment_notes: '',
                conclusions_bottle_ageing: '',
                conclusions_bottle_ageing_notes: '',
            },
        }
    },
    components: {
        Loading,
    },
    async mounted() {
        this.routeID = this.$route.params.wineId;
        try {
            const docRef = await firebase.firestore().collection("wines").doc(this.routeID).get();
            this.wineRef = docRef;
            this.currentWine = docRef.data();
            this.wine.wineId = this.currentWine.wineId;
            this.wine.vintage = this.currentWine.vintage;
            this.wine.brand = this.currentWine.brand;
            this.wine.name = this.currentWine.name;
            this.wine.appearance_intensity = this.currentWine.appearance_intensity;
            this.wine.appearance_colour = this.currentWine.appearance_colour;
            this.wine.nose_intensity = this.currentWine.nose_intensity;
            this.wine.nose_aroma_characteristics = this.currentWine.nose_aroma_characteristics;
            this.wine.palate_sweetness = this.currentWine.palate_sweetness;
            this.wine.palate_acidity = this.currentWine.palate_acidity;
            this.wine.palate_tannin_level = this.currentWine.palate_tannin_level;
            this.wine.palate_tannin_nature = this.currentWine.palate_tannin_nature;
            this.wine.palate_alcohol = this.currentWine.palate_alcohol;
            this.wine.palate_body = this.currentWine.palate_body;
            this.wine.palate_flavour_intensity = this.currentWine.palate_flavour_intensity;
            this.wine.palate_flavour_characteristics = this.currentWine.palate_flavour_characteristics;
            this.wine.palate_other_observations = this.currentWine.palate_other_observations;
            this.wine.palate_finish = this.currentWine.palate_finish;
            this.wine.conclusions_quality_assesment = this.currentWine.conclusions_quality_assesment;
            this.wine.conclusions_quality_assesment_notes = this.currentWine.conclusions_quality_assesment_notes;
            this.wine.conclusions_bottle_ageing = this.currentWine.conclusions_bottle_ageing;
            this.wine.conclusions_bottle_ageing_notes = this.currentWine.conclusions_bottle_ageing_notes;
        } catch(error) {
            console.log("Error getting documents: ", error);
        }
    },
    methods: {
        async updateWine() {
            this.loading = true;
            try {
                console.log(this.wineRef.id)
                await firebase.firestore().collection("wines").doc(this.wineRef.id).update({
                    photo: "https://images.unsplash.com/photo-1504279577054-acfeccf8fc52?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2467&q=80",
                    vintage: this.wine.vintage,
                    brand: this.wine.brand,
                    name: this.wine.name,
                    appearance_intensity: this.wine.appearance_intensity,
                    appearance_colour: this.wine.appearance_colour.toString().split(","),
                    nose_intensity: this.wine.nose_intensity,
                    nose_aroma_characteristics: this.wine.nose_aroma_characteristics.toString().split(","),
                    palate_sweetness: this.wine.palate_sweetness,
                    palate_acidity: this.wine.palate_acidity,
                    palate_tannin_level: this.wine.palate_tannin_level,
                    palate_tannin_nature: this.wine.palate_tannin_nature,
                    palate_alcohol: this.wine.palate_alcohol,
                    palate_body: this.wine.palate_body,
                    palate_flavour_intensity: this.wine.palate_flavour_intensity,
                    palate_flavour_characteristics: this.wine.palate_flavour_characteristics.toString().split(","),
                    palate_other_observations: this.wine.palate_other_observations.toString().split(","),
                    palate_finish: this.wine.palate_finish,
                    conclusions_quality_assesment: this.wine.conclusions_quality_assesment,
                    conclusions_quality_assesment_notes: this.wine.conclusions_quality_assesment_notes,
                    conclusions_bottle_ageing: this.wine.conclusions_bottle_ageing,
                    conclusions_bottle_ageing_notes: this.wine.conclusions_bottle_ageing_notes,
                })
                console.log("Document written to ID: ", this.wineRef.id);
                this.loading = false;
                this.$router.push({ name: "Admin"});
            } catch (error) {
                this.loading = false;
                console.error("Error adding document: ", error);
            }
        }
    },
    computed: {
        isDisable() {
            return (
                !this.wine.vintage > 0 ||
                !this.wine.brand > 0 ||
                !this.wine.name > 0 ||
                !this.wine.appearance_intensity > 0 ||
                !this.wine.appearance_intensity> 0 ||
                !this.wine.appearance_colour> 0 ||
                !this.wine.nose_intensity> 0 ||
                !this.wine.nose_aroma_characteristics> 0 ||
                !this.wine.palate_sweetness> 0 ||
                !this.wine.palate_acidity> 0 ||
                !this.wine.palate_tannin_level> 0 ||
                !this.wine.palate_tannin_nature> 0 ||
                !this.wine.palate_alcohol> 0 ||
                !this.wine.palate_body> 0 ||
                !this.wine.palate_flavour_intensity> 0 ||
                !this.wine.palate_flavour_characteristics> 0 ||
                !this.wine.palate_other_observations> 0 ||
                !this.wine.palate_finish> 0 ||
                !this.wine.conclusions_quality_assesment> 0 ||
                !this.wine.conclusions_quality_assesment_notes> 0 ||
                !this.wine.conclusions_bottle_ageing> 0 ||
                !this.wine.conclusions_bottle_ageing_notes> 0
            );
  }
    }
};
</script>

<style lang="scss" scoped>
.create-post {
    display: flex;
    flex-direction: column;
    margin: 0 auto;

    .header {
        position: absolute;
        left: 10px;
        margin-top: 10px;

        button {
            transition: all .5s ease;
            cursor: pointer;
            padding: 6px 12px;
            background-color: #545454;
            color: #fff;
            border-radius: 20px;
            border: none;
        }

        button:hover {
            background-color: rgba(54,54,54,.7);
        }   
    }

    form {
        display: flex;
        align-items: center;
        flex-direction: column;
        flex-wrap: nowrap;
        height: 100%;
        font-family: 'Montserrat', sans-serif;
        color: #545454;
        padding: 0 50px;

        h2 {
            text-align: center;
            font-size: 32px;
            color: #545454;
            margin: 50px 0px 30px 0px;
            font-family: 'Cinzel', serif;
            font-size: 40px;
        }
        
        .inputs {
            align-items: center;

            > * {
                min-width: 300px;
            }

            .section-heading{
                font-family: 'Cinzel', serif;
                font-size: 32px;
                font-weight: 600;
                letter-spacing: 1px;
            }

            .section {
                padding: 10px;

                .divider {
                    margin: 0px;
                    width: 100%;
                    border: 0;
                    border-top: 1px solid #909090;
                }

                .question {
                    display: flex;
                    flex-direction: column;
                    margin: 5px;

                    .double-answer {
                        display: flex;
                        flex-direction: column;
                    }
                    
                    .label {
                        font-size: 16px;
                        margin: 2.5px 0px 2.5px 0px;
                    }

                    .input {
                        display: table-cell;
                        vertical-align: middle;
                        color: white;
                        margin: 2.5px 0px 2.5px 0px;

                        input {
                            width: 100%;
                            font-family: 'Montserrat', sans-serif;
                            font-size: 16px;
                            color: white;
                            padding: 2px 5px;
                            border: 1px solid #545454;
                            border-radius: 4px;
                            background-color: #545454;
                            padding-left: 9px;
                        }

                        ::-webkit-outer-spin-button,
                        ::-webkit-inner-spin-button {
                            -webkit-appearance: none;
                            margin: 0;
                        }

                        ::placeholder {
                            color: white;
                            opacity: 0.5; 
                        }

                        textarea {
                            resize: none;
                            width: 100%;
                            font-family: 'Montserrat', sans-serif;
                            font-size: 16px;
                            color: white;
                            padding: 2px 5px;
                            border: 1px solid #545454;
                            border-radius: 4px;
                            background-color: #545454;
                            padding-left: 9px;
                            overflow-wrap: break-word;
                        }
                    }

                    .select-input {
                        font-size: 16px;
                        font-family: 'Montserrat', sans-serif;
                        background-color: #545454;
                        color: white;
                        padding: 2px 5px;
                        border-radius: 4px;
                        margin: 2.5px 0px 2.5px 0px;
                        width: fit-content;
                    }
                }
            }
        }

        button {
            transition: all .5s ease;
            cursor: pointer;
            margin: 40px;
            padding: 12px 24px;
            background-color: #545454;
            color: #fff;
            border-radius: 20px;
            border: none;
            text-transform: uppercase;
            max-width: 100px;
        }

        button:hover {
            background-color: rgba(54,54,54,.7);
        }

        button:disabled {
            background-color: rgba(54,54,54,.7);
            color: #909090;
            cursor: not-allowed;
        }
    }
}
.error {
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  color: red;
}
</style>