import axios from "axios";
import firebase from "firebase/app";

const accessKey = "s5a5vxZAkp6hClNZlGKYhEnqULzzVBLATsR7RkQXVoQ";

const apiEndpoint = "https://api.unsplash.com/";
const photoEndpoint = `${apiEndpoint}search/photos`;

export const getPhoto = async () => {
    try {
        var found = false;
        var firestorePhotos = [];

        var photoURL = "";
        var photoId = "";

        const docRefs = await firebase.firestore().collection("photos").get();
            await docRefs.forEach((docRef) => {
                console.log("[docRef.data()] =>", docRef.data())
                firestorePhotos.push(docRef.data())
            })
            console.log("[firestorePhotos] =>", firestorePhotos)

        while (found == false) {

            var page = 1;
            const res = await axios.get(photoEndpoint, {
                params: {
                    client_id: accessKey,
                    page: page,
                    query: "vineyard",
                    orientation: "landscape",
                }
            });

            if (res.status == 200) {
                const queryData = res.data.results;
                console.log("[queryData]", queryData);

                queryData.forEach((queryPhoto) => {
                    var copy = false;
                    firestorePhotos.forEach((photoData) => {
                        console.log("[firestorePhoto data] =>", photoData)
                        if (photoData.photoId == queryPhoto.id) {
                            copy = true;
                        }
                    })
                    if (copy == false) {
                        photoId = queryPhoto.id;
                        photoURL = queryPhoto.urls.regular;
                        found = true;
                    }
                })
                
                if (found) {
                    try {
                        const docRef = await firebase.firestore().collection("photos").add({
                            photoId: photoId,
                            photoURL: photoURL,
                        })
                        console.log("Document written with ID: ", docRef.id);
                        return photoURL
                    } catch (error) {
                        console.error("Error adding document: ", error);
                    }
                } else {
                    page += 1;
                }
            } else {
                return null;
            }
        }
    } catch (exc) {
        console.error(exc);
        return null;
    }
};