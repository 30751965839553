<template>
        <div class="wrapper">
            <div class="container">
                <div class="info">
                    <div class="vintage">{{post.vintage}}</div>
                    <div class="brand">{{post.brand}}</div>
                    <div class="name">{{post.name}}</div>
                </div>
            </div>
            <div class="buttons">
                <div class="vr"></div>
                <div class="icon" @click="editPost()"><Edit class="edit"/></div>
                <div class="icon" @click="deletePost()"><Delete class="delete"/></div>
            </div>
        </div>
</template>

<script>
import Edit from "../assets/Icons/edit-regular.svg";
import Delete from "../assets/Icons/trash-regular.svg";
import firebase from "firebase/app";
export default {  
    name: "WinePost",
    props: ["post"],
    components: {
        Edit,
        Delete,
    },
    methods: {
        editPost() {
            this.$router.push({ name: "Edit Wine", params: { wineId: this.post.wineId } });
        },
        async deletePost() {
            var r = confirm("Are you sure?");
            if (r == true) {
                try {
                    await firebase.firestore().collection("wines").doc(this.post.wineId).delete()
                    console.log("Document successfully deleted!");
                    this.$emit('deleted', this.post);
                } catch (error) {
                    console.error("Error removing document: ", error);
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>

.wrapper {
    width: 100%;
    height: 50px;
    border: 1px solid #545454;
    border-radius: 8px;
    display: flex;
    overflow: hidden;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    font-family: 'Montserrat', sans-serif;
    margin: 5px 0px;

    .container {
        flex-grow: 1;
        display: flex;
        flex-basis: 50%;
        border-radius: 8px;
        flex-wrap: nowrap;
        padding: 10px 0px;
        overflow: scroll;

        ::-webkit-scrollbar {
            display: none;
        }

        .info {
            display: flex;
            align-content: center;
            flex-wrap: nowrap;

            > * {
                padding: 2.5px 6px;
                white-space: nowrap
            }

            .vintage {
                min-width: 70px;
                display: inline-block;
            }

            .brand {
                min-width: 150px;
                display: inline-block;
                font-weight: 600;
            }

            .name {
                min-width: 200px;
                display: inline-block;
            }
        }
    }
    .buttons {
        height: 100%;
        position: relative;
        display: flex;
        flex-wrap: nowrap;
        float: right;
        width: fit-content;
        align-items: center;

        .vr {
            height: 100%;
            border-right: 1px solid #545454;
            width: 0px;
            margin: 0px 0px;
        }

        > .icon {
            width: 45px;
            height: 45px;
            margin: 2.5px 6px; 
            transition: all .5s ease;
            cursor: pointer;
            padding: 6px 6px;
            border: none;
        }

        .icon:hover {
            .edit,
            .delete {
                path {
                fill: rgba(54,54,54,.7);
                }
            }
        }

        .edit,
        .delete {
            path {
                fill: #545454;
            }
            pointer-events: none;
            height: 100%;
            width: auto;
        }
    }
}
</style>