<template>
    <div class="form-wrap">
        <form class="login">
            <h2>Login to RMD.WINE</h2>
            <div class="inputs">
                <div class="input">
                    <input type="text" placeholder="Email" v-model="email">
                    <email class="icon"/>
                </div>
                <div class="input">
                    <input type="password" placeholder="Password" v-model="password">
                    <password class="icon"/>
                </div>
                <div v-show="error" class="error">{{ this.errorMsg }}</div>
            </div>
            <button class="button" @click.prevent="signIn">Sign In</button>
            <div class="angle"></div>
        </form>
        <div class="background"></div>
    </div>
</template>

<script>
import email from "../assets/Icons/envelope-regular.svg";
import password from "../assets/Icons/lock-alt-solid.svg";
import firebase from "firebase/app";
import "firebase/auth";
import "../firebase/firebaseInit"
export default {
    name: "Login",
    components: {
        email,
        password,
    },
    data() {
        return {
            email: null,
            password: null,
            error: null,
            errorMsg: "",
        };
    },
    methods: {
        signIn() {
            firebase.auth().signInWithEmailAndPassword(this.email, this.password).then(() => {
                this.$router.push({ name: "Admin"});
                this.error = false;
                this.errorMsg = "";
            }).catch((err) => {
                this.error = true;
                this.errorMsg = err.message;
            });
        }
    }
}
</script>

<style lang="scss">
.form-wrap {
  overflow: hidden;
  display: flex;
  height: 100vh;
  justify-content: center;
  align-self: center;
  margin: 0 auto;
  width: 90%;
  @media (min-width: 900px) {
    width: 100%;
  }
  .login {
    margin-bottom: 32px;
  }
  form {
    padding: 0 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    @media (min-width: 900px) {
      padding: 0 50px;
    }
    h2 {
      text-align: center;
      font-size: 32px;
      color: #545454;
      margin-bottom: 40px;
      @media (min-width: 900px) {
        font-size: 40px;
      }
    }
    button {
        transition: all .5s ease;
        cursor: pointer;
        margin-top: 24px;
        padding: 12px 24px;
        background-color: #545454;
        color: #fff;
        border-radius: 20px;
        border: none;
        text-transform: uppercase;
    }
    button:hover {
        background-color: rgba(48,48,48,.7);
    }
    .inputs {
      width: 100%;
      max-width: 350px;
      .input {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 8px;
        input {
          width: 100%;
          border: none;
          background-color: #f2f7f6;
          padding: 4px 4px 4px 30px;
          height: 50px;
          &:focus {
            outline: none;
          }
        }
        .icon {
          width: 12px;
          position: absolute;
          left: 6px;
        }
      }
    }
    .angle {
      display: none;
      position: absolute;
      background-color: #fff;
      transform: rotateZ(3deg);
      width: 60px;
      right: -30px;
      height: 200%;
      min-height: 300px;
      @media (min-width: 900px) {
        display: initial;
      }
    }
  }
  .background {
    display: none;
    flex: 2;
    background-size: cover;
    background-image: url("../assets/background.png");
    width: 100%;
    height: 100%;
    @media (min-width: 900px) {
      display: initial;
    }
  }
}
.error {
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  color: red;
}
</style>