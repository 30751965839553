<template>
    <div class="blog-wrapper">
        <div v-if="post.welcomeScreen" class="blog-content">
            
            <div class="title-tile">
                <div class="overtint"></div>
                <img src="../assets/vineyard.jpeg">
                <div class="title">RMD.WINE</div>
            </div>
            
            <div class="welcome-tile">
                <img class="photo" :src="post.photo">
                <div class="main-text">{{post.mainText}}</div>
                <hr class="divider">
                <div class="sub-text">{{post.subText}}</div>
                <ul>
                    <li>
                        <a href="mailto:ryan.m.duffy@outlook.com"><envelope class="svg-icon"/></a>
                    </li>
                    <li>
                        <a href="https://www.instagram.com/lordrmduffy/" target="_blank"><instagram class="svg-icon"/></a>
                    </li>
                    <li>
                        <a href="https://www.linkedin.com/in/ryanduffy95/" target="_blank"><linkedin class="svg-icon"/></a>
                    </li>
                </ul>
            </div>

        </div>

        <div v-else class="blog-content">

            <div class="title-tile">
                <div class="overtint"></div>
                <img :src="post.photo">
                <div class="title">
                    <div class="vintage">{{post.vintage}}</div>
                    <div class="brand">{{post.brand}}</div>
                    <div class="name">{{post.name}}</div>
                </div>
            </div>

            <div class="content-tile">
                <div class="container">
                    <div class="col-1">
                        <div class="section-heading">Appearance</div>
                        <hr class="section-underline">
                        <div class="single-answer">
                            <div>Intensity</div>
                            <div class="answer">{{post.appearance_intensity}}</div>
                        </div>
                        <div class="list-answer">
                            <div>Colour</div>
                            <ul>
                                <li v-for="item in post.appearance_colour" :key="item" class="answer">
                                    {{item}}
                                </li>
                            </ul>
                        </div>
                        
                        <br>

                        <div class="section-heading">Nose</div>
                        <hr class="section-underline">
                        <div class="single-answer">
                            <div>Intensity</div>
                            <div class="answer">{{post.nose_intensity}}</div>
                        </div>
                        <div class="list-answer-col">
                            <div>Aroma Characteristics</div>
                            <ul>
                                <li v-for="item in post.nose_aroma_characteristics" :key="item" class="answer">
                                    {{item}}
                                </li>
                            </ul>
                        </div>

                        <br>

                        <div class="section-heading">Conclusions</div>
                        <hr class="section-underline">
                        <div class="single-answer">
                            <div>Quality Assesment</div>
                            <div class="answer">{{post.conclusions_quality_assesment}}</div>
                        </div>
                        <div class="note-answer">
                            <div class="vr"></div>
                            <div>{{post.conclusions_quality_assesment_notes}}</div>
                        </div>
                        <div class="single-answer">
                            <div>Bottle Ageing</div>
                            <div class="answer">{{post.conclusions_bottle_ageing}}</div>
                        </div>
                        <div class="note-answer">
                            <div class="vr"></div>
                            <div>{{post.conclusions_bottle_ageing_notes}}</div>
                        </div>
                    </div>
                
                    <div class="col-2">
                        <div class="section-heading">Palate</div>
                        <hr class="section-underline">
                        <div class="single-answer">
                            <div>Sweetness</div>
                            <div class="answer">{{post.palate_sweetness}}</div>
                        </div>
                        <div class="list-answer">
                            <div>Acidity</div>
                            <div class="answer">{{post.palate_acidity}}</div>
                        </div>
                        <div class="single-answer">
                            <div>Tannin</div>
                            <ul>
                                <li v-for="item in [post.palate_tannin_level, post.palate_tannin_nature]" :key="item" class="answer">
                                    {{item}}
                                </li>
                            </ul>
                        </div>
                        <div class="single-answer">
                            <div>Alcohol</div>
                            <div class="answer">{{post.palate_alcohol}}</div>
                        </div>
                        <div class="single-answer">
                            <div>Body</div>
                            <div class="answer">{{post.palate_body}}</div>
                        </div>
                        <div class="single-answer">
                            <div>Flavour Intensity</div>
                            <div class="answer">{{post.palate_flavour_intensity}}</div>
                        </div>
                        <div class="list-answer-col">
                            <div class="single-heading">Flavour Characteristics</div>
                            <ul>
                                <li v-for="item in post.palate_flavour_characteristics" :key="item" class="answer">
                                    {{item}}
                                </li>
                            </ul>
                        </div>
                        <div class="list-answer-col">
                            <div class="single-heading">Other Observations</div>
                            <ul>
                                <li v-for="item in post.palate_other_observations" :key="item" class="answer">
                                    {{item}}
                                </li>
                            </ul>
                        </div>
                        <div class="single-answer">
                            <div>Finish</div>
                            <div class="answer">{{post.palate_finish}}</div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
import envelope from "../assets/Icons/envelope-regular.svg";
import instagram from "../assets/Icons/instagram-brands.svg";
import linkedin from "../assets/Icons/linkedin-brands.svg";
export default {  
    name: "WinePost",
    props: ["post"],
    components: {
    envelope,
    instagram,
    linkedin,
  },
};
</script>

<style lang="scss" scoped>

body {
  margin: 0;
}

.blog-wrapper {
    width: 100vw;
    background-color: grey;
  
    .blog-content {
        display: flex;
        @media (max-width: 1200px) {
            flex-direction: column !important;
        }

        > * {
            flex-basis: 100%;
            flex-grow: 1;
            color: white;
            @media (max-width: 1200px) {
                height: 100vw;
            }
        }
        
        .title-tile {
            min-height: 50vw;
            position: relative;
            text-align: center;
            color: white;
            font-family: 'Cinzel', serif;
            font-size: 64px;
            font-weight: 600;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                overflow: hidden;
            }
        }

        .overtint {
            position: absolute;
            background-color: rgba(0,0,0,0.25);
            width:100%;
            height:100%;
        }

        .welcome-tile {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            background-color: white;
            font-family: 'Montserrat', sans-serif;

            > * {
                margin: 10px;
            }

            ul {
                display: flex;
                gap: 16px;
                li {
                    display: flex;
                    align-items: center;
                    .svg-icon {
                        width: 24px;
                        height: auto;
                        color: #545454;
                    }
                }
            }

            .main-text {
                width: 65%;
                color: #545454;
                font-size: 28px;
                font-weight: 400;
            }

            .sub-text {
                color: black;
                font-size: 22px;
                font-weight: 300;
            }

            .divider {
                margin: 0px;
                width: 80%;
                border: 0;
                border-top: 1px solid #909090;
            }
        }

        .content-tile {
            display: flex;
            align-items: center;
            background-color: white;

            .container {
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                color: #545454;
                padding: 10px 0px;
                font-family: 'Montserrat', sans-serif;
                
                > * {
                    flex: 1 1 0;
                    padding: 10px 25px;
                    min-width: 300px;
                }

            }

            hr {
                margin: 0px;
                border: 0;
                border-top: 1px solid #909090;
            }

            .section-heading {
                font-family: 'Cinzel', serif;
                font-size: 16px;
                font-weight: 600;
                letter-spacing: 1px;
            }

            .section-underline {
                margin-bottom: 10px;
            }

            .single-answer {
                display: flex;
                justify-content: space-between;
                align-content: center;

                > * {
                    display: flex;
                    align-items: center;
                }
            }

            .list-answer {
                display: flex;
                justify-content: space-between;
                align-content: center;

                > * {
                    display: flex;
                    align-items: center;
                }
            }

            .list-answer-col {
                display: flex;
                justify-content: space-between;
                align-content: center;
                flex-direction: column;

                > * {
                    margin: 2.5px 0px 2.5px 5px;
                }
            }

            .note-answer {
                display: flex;
                flex-direction: row;
                align-content: center;

                .vr {
                    border-right: 1px solid #909090;
                    width: 20px;
                    margin: 5px 20px;
                }
            }

            .answer {
                display: inline;
                float: right;
                font-size: 14px;
                list-style-type: none;
                background-color: #545454;
                color: white;
                padding: 2px 5px;
                border-radius: 4px;
                margin: 2.5px 0px 2.5px 5px;
            }

            .single-heading {
                margin: 2.5px 0px;
            }
        }
    
        .title {
            width: 90%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: white;

            .vintage, .brand {
                font-size: 42px;
                font-weight: 600;
                letter-spacing: 1px;
            }

            .name {
                font-size: 36px;
                font-weight: 400;
                letter-spacing: 1px;
                padding-bottom: 20px;
            }
        }
        
        .photo {
            width: 45%;
            height: calc(50vw * 0.45);
            border-radius: 50%;
            @media (max-width: calc(1200px)) {
                height: calc(100vw * 0.45);
            }
        }
    }

}
</style>