<template>
  <div class="home">
    <WinePost :post="welcomeScreen" />
    <WinePost :post="post" v-for="(post, idx) in winePosts" :key="idx" />
  </div>
</template>

<script>
import WinePost from "../components/WinePost";
import firebase from "firebase/app"
export default {
  name: "Home",
  components: {WinePost},
  data() {
    return {
      welcomeScreen: {
        photo: require("../../src/assets/ryan.jpeg"),
        mainText: "Here you can find a personal list of wines I have tasted and noted",
        subText: "Find my contact details through a selection of mediums below",
        welcomeScreen: true,
      },
      winePosts: [],
    };
  },
  mounted () {
    this.fetchWines()
  },
  methods: {
    async fetchWines () {
      firebase.firestore().collection("wines").orderBy('createdOn', 'desc')
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          this.winePosts.push(doc.data())
        });
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });
      console.log('[fetchWines] data', this.winePosts)
    }
  }
};
</script>
