<template>
  <div class="app-wrapper">
    <div class="app">
      <router-view />
      <Footer v-if="footer"/>
    </div>
  </div>
</template>

<script>
import Footer from "./components/Footer.vue";
export default {
  name: "app",
  components: {Footer},
  data() {
    return {
      footer: null,
    };
  },
  created() {
    this.checkRoute();
  },
  mounted() {},
  methods: {
    checkRoute() {
      if (
        this.$route.name === "Home"
      ) {
        this.footer = true;
        return;
      }
      this.footer = false;
    }
  },
  watch: {
    $route() {
      this.checkRoute();
    }
  },
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400;500;600;700;800;900&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.container {
  max-width: 1440px;
  margin: 0 auto;
}

.link {
  cursor: pointer;
  text-decoration: none;
  text-transform: uppercase;
  color: black;
}

.link-light {
  color: #fff;
}

.home {
  :nth-child(odd) {
    .blog-content {
      flex-direction: row;
    }
  }
  :nth-child(even) {
    .blog-content{
      flex-direction: row-reverse;
    }
  }
}
</style>
