<template>
    <div>
        <div class="nav-header-left">
            <button @click="logout()">Logout</button>
        </div>
        <div class="nav-header-right">
            <button @click="$router.push('CreateWine')">Add Wine</button>
        </div>
        <div class="wrapper">
            <h2>Admin Panel</h2>
            <div class="wine-results">
                <WineAdmin :post="post" v-for="(post, index) in winePosts" :key="index" @deleted="onItemDeleted" />
            </div>
        </div>
    </div>
</template>

<script>
import WineAdmin from "../components/WineAdmin";
import firebase from "firebase/app"
export default {
  name: "Admin",
  components: {WineAdmin},
  data() {
    return {
      winePosts: [],
    };
  },
  mounted () {
    this.fetchWines()
  },
  methods: {
    async fetchWines () {
      firebase.firestore().collection("wines").orderBy('createdOn', 'desc')
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // console.log(doc.id, " => ", doc.data());
          this.winePosts.push(doc.data())
        });
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });
      console.log('[fetchWines] data', this.winePosts)
    },
    logout() {
        firebase.auth().signOut();
        this.$router.push('/')
    },

    onItemDeleted(item) {
        const index = this.winePosts.indexOf(item);
        this.winePosts.splice(index, 1)
    }
  }
};
</script>

<style lang="scss">

.nav-header-left {
    position: absolute;
    margin-top: 10px;
    left: 10px;

    button {
        transition: all .5s ease;
        cursor: pointer;
        padding: 6px 12px;
        background-color: #545454;
        color: #fff;
        border-radius: 20px;
        border: none;
    }

    button:hover {
        background-color: rgba(54,54,54,.7);
    }   
}

.nav-header-right {
    position: absolute;
    margin-top: 10px;
    right: 10px;

    button {
        transition: all .5s ease;
        cursor: pointer;
        padding: 6px 12px;
        background-color: #545454;
        color: #fff;
        border-radius: 20px;
        border: none;
    }

    button:hover {
        background-color: rgba(54,54,54,.7);
    }   
}

.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Cinzel', serif;
    color: #545454;
    font-size: 24px;
    // font-family: 'Montserrat', sans-serif;

    h2 {
        text-align: center;
        font-size: 32px;
        color: #545454;
        margin: 50px 0px 30px 0px;
        font-family: 'Cinzel', serif;
        font-size: 40px;
    }

    .wine-results {
        width: 80%;
        @media (max-width: 800px) {
            width: 95%
        }
    }
}
</style>